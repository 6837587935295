import React, { useState, useEffect } from "react";
import { Niivue } from '@niivue/niivue';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import NiivuePanel from './NiivuePanel';


const nv = new Niivue({
    loadingText: 'loading...'
})

const shoulderSegments = [
    { id: "fatDeltoid", name: "Deltoid Fat" },
    { id: "muscleDeltoid", name: "Deltoid Muscle" },
    { id: "completeScapula", name: "Scapula" },
];
let shoulderSegmentIds = shoulderSegments.map(shoulderSegment => shoulderSegment.id);

export default function NiivueOrchard(props) {
    const [allVolumes, setAllVolumes] = useState(props.volumes);
    const [volumes, setVolumes] = useState(props.volumes);
    const [shoulderSegmentName, setShoulderSegmentName] = useState(['fatDeltoid','muscleDeltoid','completeScapula']);

    const updateImageDisplay = () => {
        const selectedIds = shoulderSegmentIds.filter((shoulderSegmentId) => shoulderSegmentName.includes(shoulderSegmentId));
        setVolumes(allVolumes.filter(v => selectedIds.includes(v.muscle)));
    };

    useEffect(() => {
        updateImageDisplay();
    }, [shoulderSegmentName]);

    const handleSegmentChange = (event) => {
        setShoulderSegmentName(event.target.value);
    };

	return (
        <>
        <Grid item xs={8}>
            <FormControl fullWidth>
                <InputLabel id="segment-checkbox-select-label">View Segment(s)</InputLabel>
                <Select
                    labelId="segment-checkbox-select-label"
                    id="segment-checkbox-select"
                    multiple
                    value={shoulderSegmentName}
                    label="View Segment(s)"
                    onChange={handleSegmentChange}
                    renderValue={(selected) => shoulderSegments.filter(seg => selected.includes(seg.id)).map(seg => seg.name).join(', ')}
                    labelStyle={{ color: "#ffffff" }}
                    sx={{
                        '& .MuiSelect-select': {
                        color: '#ffffff'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'rgba(228, 219, 233, 0.25)'
                        }
                    }}
                >
                {shoulderSegments.map((shoulderSegment) => (
                    <MenuItem key={shoulderSegment.id} value={shoulderSegment.id}>
                        <Checkbox checked={shoulderSegmentName.indexOf(shoulderSegment.id) > -1} />
                        <ListItemText primary={shoulderSegment.name} />
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        <br></br>
        <Grid>
            <Box sx={{ border: '2px solid #ffffff' }}>
                <NiivuePanel nv={nv} volumes={volumes}></NiivuePanel>
            </Box>
        </Grid>
        </>
	)
}
