import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoginIcon from '@mui/icons-material/Login';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { loginRequest } from "../authConfig";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

import '../styles/App.css';

export const Home = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Container maxWidth='lg'>
            <br></br>
            <br></br>
            <Grid container columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={4}>
                <Button 
                  variant="contained" 
                  component={RouterLink} 
                  to={'/iris/home'}
                  style={{width: '100%', height: '100%', minWidth: '250px', minHeight: '250px'}}
                >
                  <Typography variant='h2'>IRIS</Typography>
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <a href="https://shoulder-llm-app.azurewebsites.net/" style={{ textDecoration: 'none' }}>
                <Button 
                  variant="contained" 
                  style={{width: '100%', height: '100%'}}
                >
                  <Typography variant='h2'>SAGE</Typography>
                </Button>
                </a>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
              <Button 
                  disabled
                  variant="contained" 
                  style={{width: '100%', height: '100%', backgroundColor: '#1095c1'}}
                >
                  <Typography variant='h2'>PREDICT+</Typography>
                </Button>
              </Grid>
            </Grid>
          </Container>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <br></br>
        <div class="centeredHorizontally">
          <Button variant="contained" color="primary" startIcon={<LoginIcon />} onClick={handleLoginRedirect}>
            Login with Exactech
          </Button>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};