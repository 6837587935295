import React, {useEffect, useRef} from "react";

const NiivuePanelFunction = ({ nv, volumeList }) => {
  const canvas = useRef();
  useEffect(() => {
      nv.attachToCanvas(canvas.current);
      nv.loadMeshes(volumeList);
  }, [nv, volumeList]);
  return <canvas ref={canvas} height={920} width={920} />;
};

function NiivuePanel(props) {
  if (props.nv != null && props.volumes != null)  {
    return <div> <NiivuePanelFunction nv={props.nv} volumeList={props.volumes}> </NiivuePanelFunction></div>;
  }
}

function arePropsEqual(prevProps, nextProps) {
  return prevProps.nv === nextProps.nv && prevProps.volumes === nextProps.volumes;
}

export default React.memo(NiivuePanel, arePropsEqual);
