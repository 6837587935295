import BottomNavigation from '@mui/material/BottomNavigation';
import { AuthenticatedTemplate } from "@azure/msal-react";

import Navigation from './Navigation';

import '../styles/App.css';

export const PageLayout = (props) => {
    return (
        <>
            <Navigation />
            <br />
            <div>
                {props.children}
            </div>
            <AuthenticatedTemplate></AuthenticatedTemplate>
            <div>
                <br></br>
                <BottomNavigation 
                    sx={{
                        backgroundColor: '#11191f',
                        width: '100%',
                        marginTop: 25,
                        bottom: 0,
                    }}
                >
                    <span color='#ffffff' backgroundColor='#11191f'>This is a preview | AST Machine Learning | © 2023 Exactech Inc</span>
                </BottomNavigation>
            </div>
        </>
    );
};