import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

export const ImagesListView = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiIris.scopes
    });

    const [deltoidImages, setDeltoidImages] = useState(props.deltoidImages);
    const [imageLabels, setImageLabels] = useState(props.imageLabels);
    const [imagesMetadata, setImagesMetadata] = useState(props.imagesMetadata);
    const [imagesData, setImagesData] = useState([]);
    const [filteredImagesData, setFilteredImagesData] = useState([]);
    const [imageName, setImageName] = useState('');
    const [gender, setGender] = useState('None');
    const [diagnosis, setDiagnosis] = useState('None');
    const [kernel, setKernel] = useState('None');
    const [age, setAge] = useState([0, 100]);
    const [deltoidLabel, setDeltoidLabel] = useState('None');
    const [scapulaLabel, setScapulaLabel] = useState('None');

    const navigate = useNavigate();

    const handleShowImageDetails = (params) => {
      navigate('/iris/imageresult', {state: { image: params.row }});
    };

    const updateFilteredImagedData = () => {
      let filterData = imagesData;

      if (imageName != '') {
        filterData = filterData.filter(imageData => imageData.id.split('/').pop().toUpperCase() == imageName.toUpperCase());
      }
      if (gender != 'None') {
        filterData = filterData.filter(imageData => 'patient' in imageData && 'gender' in imageData.patient && imageData.patient.gender == gender);
      }
      if (diagnosis != 'None') {
        filterData = filterData.filter(imageData => 'patient' in imageData && 'diagnosis' in imageData.patient && imageData.patient.diagnosis == diagnosis);
      }
      if (kernel != 'None') {
        filterData = filterData.filter(imageData => 'image' in imageData && 'kernel' in imageData.image && imageData.image.kernel == kernel);
      }
      if (age != 'None') {
        filterData = filterData.filter(imageData => 'patient' in imageData && 'age' in imageData.patient && imageData.patient.age >= age[0] && imageData.patient.age <= age[1]);
      }
      if (deltoidLabel != 'None') {  
        let deltoidLabelToCompare = deltoidLabel;
        if (deltoidLabel == 'Not labeled') {
          deltoidLabelToCompare = null
        }
        filterData = filterData.filter(imageData => imageData.deltoidLabel == deltoidLabelToCompare);
      }
      if (scapulaLabel != 'None') {
        let scapulaLabelToCompare = scapulaLabel;
        if (scapulaLabel == 'Not labeled') {
          scapulaLabelToCompare = null
        }
        filterData = filterData.filter(imageData => imageData.scapulaLabel == scapulaLabelToCompare);
      }
      setFilteredImagesData(filterData);
    }

    const handleImageNameChange = (event) => {
      setImageName(event.target.value);
    };

    const handleGenderChange = (event) => {
      setGender(event.target.value);
    };

    const handleDiagnosisChange = (event) => {
      setDiagnosis(event.target.value);
    };

    const handleKernelChange = (event) => {
      setKernel(event.target.value);
    };

    const handleAgeChange = (event, newAge) => {
      setAge(newAge);
    };

    const handleDeltoidLabelChange = (event) => {
      setDeltoidLabel(event.target.value);
    };

    const handleScapulaLabelChange = (event) => {
      setScapulaLabel(event.target.value);
    };

    useEffect(() => {
      updateFilteredImagedData();
    },[imageName, gender, diagnosis, kernel, age, deltoidLabel, scapulaLabel]);

    useEffect(() => {
      let merged1 = deltoidImages;
      let merged2 = [];
      let merged3 = [];

      for(let i=0; i < merged1.length; i++) {
        let imageFeedbacks = [];
        if (imageLabels.length > 0) {
          imageFeedbacks = imageLabels.filter(imageLabel => {
            return imageLabel.image_id == merged1[i].id
          })
        }
        merged2.push({
        ...merged1[i], 
        ...{'feedback': imageFeedbacks}}
        );
      }

      for(let i=0; i < merged2.length; i++) {
        merged3.push({
        ...merged2[i], 
        ...(imagesMetadata.find((itmInner) => itmInner.image.id.split('/').pop() === merged1[i].id.split('/').pop()))}
        );
      }

      merged3.forEach(v => {
        if ('patient' in v) {
          v.patientGender = v.patient.gender;
          v.patientDiagnosis = v.patient.diagnosis;
          v.patientAge = v.patient.age;  
        }

        if ('image' in v) {
          v.imageKernel = v.image.kernel;
        }

        let deltoidFeedback = v.feedback.find(f => f.muscle === 'deltoid') || null;
        if (deltoidFeedback != null) {
          v.deltoidLabel = deltoidFeedback.feedback;
        } else {
          v.deltoidLabel = null;
        }

        let scapulaFeedback = v.feedback.find(f => f.muscle === 'scapula') || null;
        if (scapulaFeedback != null) {
          v.scapulaLabel = scapulaFeedback.feedback;
        } else {
          v.scapulaLabel = null;
        }
      });

      setImagesData(merged3);
      setFilteredImagesData(merged3);
    }, [execute, deltoidImages, imageLabels, imagesMetadata])

    const ageSliderMarks = [
      {
        value: age[0],
        label: age[0],
      },
      {
        value: age[1],
        label: age[1],
      },
    ];

    function getChipProps(params) {
      if (params.value == null) {
        return {
          label: "Not labeled",
          sx: { mr: 2, bgcolor: 'grey' }
        };
      } else if (params.value == 'No Correction') {
        return {
          icon: <DoneIcon/>,
          color: "success",
          label: params.value,
          sx: { mr: 2 }
        };
      } else if (params.value == 'Minor') {
        return {
          icon: <DoneIcon/>,
          color: "secondary",
          label: params.value,
          sx: { mr: 2 }
        };
      } else if (params.value == 'Major') {
        return {
          icon: <CloseIcon/>,
          color: "error",
          label: params.value,
          sx: { mr: 2 }
        };
      }
    }

    const imageListColumns = [
        { 
          field: 'id', 
          headerName: 'Image Name', 
          width: 200,
          valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            return `${params.value.split("/").pop()}`;
          }
        },
        { field: 'imageKernel', headerName: 'Kernel', width: 150 },
        { field: 'patientGender', headerName: 'Gender', width: 150 },
        { field: 'patientDiagnosis', headerName: 'Diagnosis', width: 150 },
        { field: 'patientAge', headerName: 'Age', width: 100 },
        {
          field: 'deltoidLabel',
          headerName: "Deltoid Label",
          width: 200,
          renderCell: (params) => {
            return <Chip {...getChipProps(params)} />;
          },
        },
        { 
          field: 'imageProcesssedAt',
          headerName: 'Processed On',
          width: 200,
          valueFormatter: params => moment(params?.value).format("DD/MM/YYYY")
        }
    ];

   return (
      <div>
        <br /><br />
        <Container maxWidth='lg'>
          <Typography variant='h5'>SEGMENTED IMAGES</Typography>
          <br /><br />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl variant="standard">
                <TextField id="image-name-textfield" label="Image Name" variant="standard" 
                  onChange={handleImageNameChange}
                  sx={{
                    '& .MuiInput-root': { color: '#ffffff' },
                    '& .MuiInput-underline:before': { borderBottomColor: '#ffffff' },
                    '& .MuiInput-underline:after': { borderBottomColor: '#ffffff' },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={gender}
                  label="Gender"
                  onChange={handleGenderChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Male'}>Male</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="diagnosis-select-label">Diagnosis</InputLabel>
                <Select
                  labelId="diagnosis-select-label"
                  id="diagnosis-select"
                  value={diagnosis}
                  label="Diagnosis"
                  onChange={handleDiagnosisChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'OA'}>OA</MenuItem>
                  <MenuItem value={'RCT'}>RCT</MenuItem>
                  <MenuItem value={'RCA'}>RCA</MenuItem>
                  <MenuItem value={'PTA'}>PTA</MenuItem>
                  <MenuItem value={'FX'}>FX</MenuItem>
                  <MenuItem value={'ON'}>ON</MenuItem>
                  <MenuItem value={'OTH'}>OTH</MenuItem>
                  <MenuItem value={'RA'}>RA</MenuItem>
                  <MenuItem value={'OTH'}>OTH</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="kernel-select-label">Kernel</InputLabel>
                <Select
                  labelId="kernel-select-label"
                  id="kernel-select"
                  value={kernel}
                  label="Kernel"
                  onChange={handleKernelChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'BONE'}>BONE</MenuItem>
                  <MenuItem value={'BONEPLUS'}>BONEPLUS</MenuItem>
                  <MenuItem value={'STANDARD'}>STANDARD</MenuItem>
                  <MenuItem value={'FC30'}>FC30</MenuItem>
                  <MenuItem value={'B20s'}>B20s</MenuItem>
                  <MenuItem value={'B31s'}>B31s</MenuItem>
                  <MenuItem value={'B31s'}>B31s</MenuItem>
                  <MenuItem value={'B40s'}>B40s</MenuItem>
                  <MenuItem value={'B60s'}>B60s</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Typography id="input-slider">
                Age Range
              </Typography>
              <Slider
                getAriaLabel={() => 'Age range'}
                value={age}
                marks={ageSliderMarks}
                onChange={handleAgeChange}
                valueLabelDisplay="auto"
                sx={{
                  '& .MuiSlider-thumb': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-track': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-rail': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-active': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-mark': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-markLabel': {
                    color: "#ffffff"
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="deltoid-label-select-label"> Deltoid Label</InputLabel>
                <Select
                  labelId="deltoid-label-select-label"
                  id="deltoid-label-select"
                  value={deltoidLabel}
                  label="Deltoid Label"
                  onChange={handleDeltoidLabelChange}
                  labelstyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'Major'}>Major</MenuItem>
                  <MenuItem value={'Minor'}>Minor</MenuItem>
                  <MenuItem value={'No Correction'}>No Correction</MenuItem>
                  <MenuItem value={'Not labeled'}>Not labeled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br></br>
          <br></br>
        <DataGrid
            rows={filteredImagesData}
            columns={imageListColumns}
            sx={{
                color: '#ffffff',
                boxShadow: 2,
                border: 2,
                borderColor: '#ffffff',
                mt: 2,
                '.MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer'
                },
                '& .MuiDataGrid-menuIconButton': {
                  color: '#ffffff'
                },
                '& .MuiButtonBase-root': {
                  color: '#ffffff',
                }
            }}
            onRowClick={handleShowImageDetails}
            initialState={{
              pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10]}
        />
        </Container>
        <br></br>
        <br></br>
      </div>
  );
}