import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { loginRequest } from "../authConfig";

import '../styles/App.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const IrisHome = () => {
  const authRequest = {
    ...loginRequest,
  };

    return (
      <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <Container maxWidth='lg'>
              <br></br>
              <br></br>
              <Grid container columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={6}>
                  <Button 
                    disabled
                    variant="contained" 
                    component={RouterLink} 
                    to={'/iris/home'}
                    style={{width: '100%', height: '100%', minWidth: '250px', minHeight: '250px', backgroundColor: '#1095c1'}}
                  >
                    <Typography variant='h5'>VIEW & PROCESS UPLOADED IMAGES</Typography>
                  </Button>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Button 
                      variant="contained" 
                      component={RouterLink} 
                      to={'/iris/segmentationresults'}
                      style={{width: '100%', height: '100%', minWidth: '250px', minHeight: '250px'}}
                    >
                      <Typography variant='h5'>VIEW SEGMENTATION RESULTS</Typography>
                    </Button>
                </Grid>
              </Grid>
            </Container>
      </MsalAuthenticationTemplate>
  );
};