import * as Color from 'color';

const primaryMainColor = '#1095c1';
const secondaryMainColor = '#FFA400';

const typography = {
    // Default font
    fontFamily: ['Open Sans', 'Arial'].join(','),
    // Typography overrides
    h1: {
        fontFamily: 'Open Sans',
        fontSize: 96,
        fontWeight: 200,
        letterSpacing: -1.5,
        color: 'white'
    },
    h2: {
        fontFamily: 'Open Sans',
        fontSize: 60,
        fontWeight: 300,
        letterSpacing: -0.5,
        color: 'white'
    },
    h3: {
        fontFamily: 'Open Sans',
        fontSize: 48,
        fontWeight: 300,
        letterSpacing: 0,
        color: 'white'
    },
    h4: {
        fontFamily: 'Open Sans',
        fontSize: 34,
        fontWeight: 400,
        letterSpacing: 0.25,
        color: 'white'
    },
    h5: {
        fontFamily: 'Open Sans',
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: 0,
        color: 'white'
    },
    h6: {
        fontFamily: 'Open Sans',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0.75,
        textTransform: 'uppercase',
        color: 'white'
    },
    h6white: {
        fontFamily: 'Open Sans',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0.75,
        textTransform: 'uppercase',
        color: 'white'
    },
    subtitle1: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.15,
        color: 'white'
    },
    subtitle2: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0.1,
        color: 'white'
    },
    body1: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0,
        color: 'white'
    },
    body2: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0,
        color: 'white'
    },
    button: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0.75,
        textTransform: 'uppercase',
        color: 'white'
    },
    caption: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4,
        color: 'white'
    },
    overline: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0.75,
        textTransform: 'uppercase',
        opacity: 0.6,
        color: 'white'
    },
    datagridFont: {
        fontFamily: 'Open Sans',
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: 0,
        color: 'white'
    },
};

const baseTheme = {
    typography,
    palette: {
        primary: {
            main: primaryMainColor
        },
        secondary: {
            main: secondaryMainColor
        },
        error: {
            main: '#d33b02', // Red
            dark: '#b50101',
            light: '#e57373'
        },
        warning: {
            main: '#f5ad3d', // Yellow
            dark: '#f57c00',
            light: '#ffbf60'
        },
        success: {
            main: '#27ae60', // Green
            dark: '#388e3c',
            light: '#81c784'
        },
        info: {
            main: '#2196f3', // Blue
            dark: '#1976d2',
            light: '#64b5f6'
        },
        background: {
            default: '#11191f', // Light Gray
            paper: '#000'
        },
        white: {
            main: '#ffffff'
        }
    },
    custom: {
        logos: {
            main: ``,
            mainAlt: ``
        }
    },
    overrides: {
        MuiTableCell: {
            root: {
                ...typography.body2
            },
            head: {
                ...typography.body2,
                color: Color('#000').alpha(0.54).string()
            }
        },
        MuiTab: {
            root: {
                ...typography.body2,
                textTransform: 'none'
            }
        },
        MuiAlert: {
            styleOverrides: {
              standardSuccess: {
                backgroundColor: 'green',
                color: 'white'
              },
              standardError: {
                backgroundColor: 'red',
                color: 'white'
              },
              standardWarning: {
                backgroundColor: 'orange',
                color: 'white'
              },
              standardInfo: {
                backgroundColor: 'grey',
                color: 'black',
                main: '#2196f3', // Blue
                dark: '#1976d2',
                light: '#64b5f6'
              }
            }
          },
    }
};

export default baseTheme;
