import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Slider from '@mui/material/Slider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import NiivueOrchard from "../niivueComponents/NiivueOrchard";

export const SingleImageView = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiIris.scopes
    });

    const location = useLocation();
    const [image, setImage] = useState(location.state.image);
    const [openFeedbackConf, setOpenFeedbackConf] = React.useState(false);
    const [activeImage, setActiveImage] = useState({});
    const [imagesLinks, setImagesLinks] = useState(null);


    const options = ['Download RAW NIFTI', 'Download Deltoid'];
    
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };

    const handleOnClickDownload = (imageType) => () => {
      let href = '';
      if (imageType == 'Download RAW NIFTI') {
        href = imagesLinks['image_nifti_download_link'];
      } else if (imageType == 'Download Deltoid') {
        href = imagesLinks['deltoid_mask_clean_stl_download_link'];
      }
      
      const link = document.createElement("a");
      link.download = 'raw_image.nii';
      link.href = href;
      link.click();
    };
  
    const navigate = useNavigate();

    useEffect(() => {
      if (!imagesLinks) {
        let linkData = { "id": image.id }
        execute('PUT', protectedResources.apiIris.imageDownloadLinksEndpoint, linkData).then((response) => {
          if (response) {
            const irisImageLinks = response[0];
            setImagesLinks(irisImageLinks);
          }
        });
      }
    }, [execute, imagesLinks])

    const handleBackToImageList = () => {
      navigate('/iris/segmentationresults');
    };

    const labelImage = (imageid, muscle, label) => () => {
      let feedbackData = {
        "image_id": imageid,
        "muscle": muscle,
        "feedback": label,
        "comments": "No Comments",
        "environment": "Dev",
        "input_location": "NA",
        "model_name": "Deltoid",
        "model_version": "V1.1",
        "output_location": imageid,
        "source": "ASTML Workspace",
        "user_persona": "Non-Surgeon"
      }
      execute('PUT', protectedResources.apiIris.imageFeedbackEndpoint, feedbackData).then((response) => {
          if (response && response.message === 'success') {
            let imageFeedbacks = image.feedback;
            if (imageFeedbacks.find(imageFeedback => imageFeedback.muscle === muscle)) {
              imageFeedbacks.forEach((imageFeedback, index) => {
                if(imageFeedback.muscle === muscle) {
                  imageFeedbacks[index] = feedbackData;
                }
              });
            } else {
              imageFeedbacks.push(feedbackData);
            }
            let updatedImagesData =  { ...image, feedback: imageFeedbacks };
            setImage(updatedImagesData);
            setActiveImage({ imageId: image.id.split("/").pop(), muscle: muscle, label: label });
            setOpenFeedbackConf(true);
          }
      });
    }
  
    const handleFeedbackConfClose = () => {
      setOpenFeedbackConf(false);
    };

    const Modal = ({ object }) => (
      <Dialog 
        open={openFeedbackConf} 
        maxWidth="md" 
        onClose={handleFeedbackConfClose}
        className="active" 
        PaperProps={{
          elevation: 0,
          sx: {
            border: "solid 1px black",
            backgroundColor: "#11191f"
          }
        }}
      >
        <DialogTitle>
          <span style={{color: '#ffffff'}}>{object.muscle} Sucessfully labelled</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{color: '#ffffff'}}>Successfully labeled image <b>{object.imageId}</b> with <b>{object.label}</b>.</span>          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackConfClose}>
            <span style={{color: '#ffffff'}}>Close</span>
          </Button>
        </DialogActions>
      </Dialog>
    );

    function metadataTable(object1, title) {
      return (
        <TableContainer component={Paper} sx={{ background: "#fafafa", maxHeight: 310 }}>
          <Table>
          <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#11191f",
                  border: "2px solid white",
                  "& th": {
                    color: "#ffffff"
                  }
                }}
              >
                <TableCell sx={{ fontWeight: 800 }}>{title}</TableCell>
                <TableCell sx={{ fontWeight: 800 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(object1).map((key, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell component="th" scope="row"
                    sx={{
                      border: "2px solid white",
                      backgroundColor: "#11191f",
                      color: "#ffffff"
                    }}
                  >
                    {key.split('_').map(function(x){ return x.charAt(0).toUpperCase() + x.slice(1); }).join(' ')}
                  </TableCell>
                  <TableCell align="left"
                    sx={{
                      border: "2px solid white",
                      backgroundColor: "#11191f",
                      color: "#ffffff"
                    }}
                  >{object1[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    function segmentationMetadataTable(muscle, muscle_segmentation) {
      return (
        <TableContainer component={Paper} sx={{ background: "#fafafa", maxHeight: 310 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#11191f",
                  border: "2px solid white",
                  "& th": {
                    color: "#ffffff"
                  }
                }}
              >
                <TableCell sx={{ fontWeight: 800, color: "#ffffff" }}>{muscle}</TableCell>
                <TableCell sx={{ fontWeight: 800, color: "#ffffff" }}></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(muscle_segmentation).map((key, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row"
                    sx={{
                      border: "2px solid white",
                      backgroundColor: "#11191f",
                      color: "#ffffff"
                    }}
                  >
                    {key.split('_').map(function(x){ return x.charAt(0).toUpperCase() + x.slice(1); }).join(' ')}
                  </TableCell>
                  <TableCell align="left" colSpan={2}
                    sx={{
                      border: "2px solid white",
                      backgroundColor: "#11191f",
                      color: "#ffffff"
                    }}
                  >
                    {Object.keys(muscle_seg_range).includes(key) ? (
                      muscle_segmentation[key][0]['value'] != null ? (
                      <Slider
                        disabled
                        value={muscle_segmentation[key][0]['value'] != null ? muscle_segmentation[key][0]['value'] : ''}
                        min={muscle_segmentation[key][0]['min']}
                        max={muscle_segmentation[key][0]['max']}
                        marks={[
                          {
                            value: muscle_segmentation[key][0]['min'],
                            label: muscle_segmentation[key][0]['min'] != null ? muscle_segmentation[key][0]['min'].toString() : '',
                          },
                          {
                            value: muscle_segmentation[key][0]['max'],
                            label: muscle_segmentation[key][0]['max'] != null ? muscle_segmentation[key][0]['max'].toString(): '',
                          }
                        ]}
                        valueLabelDisplay="on"
                        sx={{
                          '& .MuiSlider-thumb': {
                              color: "#ffffff"
                          },
                          '& .MuiSlider-track': {
                              color: "#ffffff"
                          },
                          '& .MuiSlider-rail': {
                              color: "#ffffff"
                          },
                          '& .MuiSlider-active': {
                              color: "#ffffff"
                          },
                          '& .MuiSlider-mark': {
                              color: "#ffffff"
                          },
                          '& .MuiSlider-markLabel': {
                            color: "#ffffff"
                          }
                        }}
                      />) : ('N.A')
                      
                    ) : (
                      muscle_segmentation[key]
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    const muscle_seg_range = {
      "normal_muscle_volume": {
        "min": 0.8,
        "max": 2.0
      },
      "normal_atrophy": {
        "min" : 1.5,
        "max": 2.5
      },
      "fatty_infiltration": {
        "min": 0,
        "max": 1000
      }
    }

    function displayImageLabel(imageLabels, muscle) {
      let label = null;
      if (imageLabels.length > 0) {
        label = imageLabels.find(imageLabel => {
          return imageLabel.muscle == muscle
        })
      }
      if (label != null) {
        const feedback = label.feedback;
        if (feedback == 'No Correction') {
          return (
            <Chip icon={<DoneIcon />} color="success" label={feedback.toUpperCase()} sx={{ mr: 2 }}/>
          )
        } else if (feedback == 'Minor') {
          return (
            <Chip icon={<DoneIcon />} color="secondary" label={feedback.toUpperCase()} sx={{ mr: 2 }}/>
          )
        } else if (feedback == 'Major') {
          return (
            <Chip icon={<CloseIcon />} color="error" label={feedback.toUpperCase()} sx={{ mr: 2 }}/>
          )
        }
      }
    }

    function displayLabelButton(imageId, muscle) {
      return (
        <Tooltip title="Label Image" placement="top" arrow>
          <ButtonGroup sx={{ mb: 2 }} variant="contained">
            <Button color="success" onClick={labelImage(imageId, muscle, 'No Correction')}>No Correction</Button>
            <Button color="secondary" onClick={labelImage(imageId, muscle, 'Minor')}>Minor</Button>
            <Button color="error" onClick={labelImage(imageId, muscle, 'Major')}>Major</Button>
          </ButtonGroup>
        </Tooltip>
      )
    }

    function displayImage(image) {
      if (image.deltoidProcesssed && image.scapulaProcesssed) {
        const volumes = [
          {url: image.fatUrl, rgba255: [255, 174, 66], muscle: 'fatDeltoid'},
          {url: image.muscleUrl, rgba255: [238, 75, 43], muscle: 'muscleDeltoid'},  
          {url: image.scapulaUrl, rgba255: [234, 221, 202], muscle: 'completeScapula'}    
        ]
        return (
          <NiivueOrchard volumes={volumes}/>
        )
      } else if (image.deltoidProcesssed) {
        const volumes = [
          {url: image.fatUr, rgba255: [255, 174, 66], muscle: 'fatDeltoid'},
          {url: image.muscleUrl, rgba255: [238, 75, 43], muscle: 'muscleDeltoid'}   
        ]
        return (
          <NiivueOrchard volumes={volumes}/>
        )
      }
    }

    return (
      <div>
        <br /><br />
        <Container maxWidth='lg'>
          <Button variant="outlined" startIcon={<ArrowBackIcon />}
          onClick={() => handleBackToImageList()}
          >
            BACK
          </Button>
          <br /><br />
          <Box sx={{ p: 2, mb: 2, border: '3px solid #ffffff' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='h6'  align="left"><strong>CASE ID: {image.id.split("/").pop()}</strong></Typography>
                {
                  image.image ? <Typography variant='subtitle1'  align="left"><strong>Kernel: {image.image.kernel}</strong></Typography> : <></>
                }
              </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                {image.deltoidProcesssed ? (
                  <>
                    {displayImageLabel(image.feedback, 'deltoid')}
                    {displayLabelButton(image.id, 'deltoid')}
                  </>
                ) : (
                  <>
                  </>
                )}
              </Grid>

              <Grid item xs={2}>
              </Grid>

              { imagesLinks != null && 'image_nifti_download_link' in imagesLinks ?
                <>
                  <Grid item xs={4}>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                    >
                      <Button onClick={handleOnClickDownload(options[selectedIndex])} startIcon={<DownloadIcon />}>
                        {options[selectedIndex]}
                      </Button>
                      <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      sx={{
                        zIndex: 1,
                      }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu" autoFocusItem>
                                {options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Grid>
                </>
                : <Alert sx={{ backgroundColor: "#000000",  color: "#ffffff" }} severity="info">Image is not available for download</Alert>
              }
            </Grid>

            <br></br>
            <br></br>

            <Grid 
              container 
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                {displayImage(image)}
              </Grid>
              <Grid item xs={6}>
                <Alert severity="info">Masks overlapped on raw CT scans will be displayed here.</Alert>
              </Grid>
            </Grid>

            <Grid container spacing={1} sx={{mt: 2}}>
              <Grid item xs={12}>
                <Grid container item spacing={1}>
                  <React.Fragment>
                    <Grid item xs={4}>
                      {
                      image.patient ? metadataTable(image.patient, "Patient Details") : 
                      (<Alert sx={{ backgroundColor: "#000000",  color: "#ffffff" }} severity="info">Patient details is not available</Alert>)
                      }
                    </Grid>
                    <Grid item xs={4}>
                      {
                        image.deltoid_segmentation ? segmentationMetadataTable('Segmentation', image.deltoid_segmentation) : 
                        (<Alert sx={{ backgroundColor: "#000000",  color: "#ffffff" }} severity="info">Segmentation details is not available</Alert>)
                      }
                    </Grid>
                    <Grid item xs={4}>
                      {
                      image.rom && image.prom ? segmentationMetadataTable('Range of Motion', Object.assign(image.rom, image.prom)) : 
                      (<Alert sx={{ backgroundColor: "#000000",  color: "#ffffff" }} severity="info">Range of Motion details is not available</Alert>)
                      }
                    </Grid>
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {openFeedbackConf ? <Modal object={activeImage} /> : null}
        <br></br>
        <br></br>
      </div>
  );
}