import { Routes, Route } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import { RouteGuard } from './components/RouteGuard';
import { PageLayout } from './components/PageLayout';
import { Home } from './pages/Home';
import { IrisHome } from './pages/IrisHome';
import { SegmentationResults } from './pages/SegmentationResults';
import { appRoles } from './authConfig';

import './styles/App.css';
import { SingleImageView } from './components/SingleImageView';

const Pages = () => {
    return (
        <Routes>
            <Route
                exact
                path="/iris/imageresult"
                element={
                    <RouteGuard roles={[appRoles.IrisUser, appRoles.Admin]}>
                        <SingleImageView />
                    </RouteGuard>
                }
            />
            <Route
                exact
                path="/iris/segmentationresults"
                element={
                    <RouteGuard roles={[appRoles.IrisUser, appRoles.Admin]}>
                        <SegmentationResults />
                    </RouteGuard>
                }
            />
            <Route
                exact
                path="/iris/home"
                element={
                    <RouteGuard roles={[appRoles.IrisUser, appRoles.Admin]}>
                        <IrisHome />
                    </RouteGuard>
                }
            />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <PageLayout>
                <Pages instance={instance} />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;
